import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Meet from "./components/Meet/Meet";
import "normalize.css";
import './App.css';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home isHomePage={true} />} />
          <Route path="/meet-us" element={<Meet />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
