import { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
import styles from "./ContactForm.module.css";

const ContactForm = ({
  show,
  onHide,
  contactName,
  serviceId,
  templateId,
  userId,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    message: "",
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalVariant, setModalVariant] = useState("success");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (formData.name && formData.email && formData.message) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(serviceId, templateId, formData, userId)
      .then((response) => {
        setModalMessage("Message sent successfully!");
        setModalVariant("success");
        setShowMessageModal(true);
      })
      .catch((err) => {
        setModalMessage("Message failed to send.");
        setModalVariant("danger");
        setShowMessageModal(true);
      });
  };

  const handleClose = () => {
    setFormData({
      name: "",
      email: "",
      tel: "",
      message: "",
    });
    onHide();
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    onHide();
  };

  return (
    <>
      {show && (
        <motion.div
          className={styles.modal}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <h2>Contact {contactName}</h2>
              <button className={styles.closeButton} onClick={handleClose}>
                &times;
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className={styles.formControl}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className={styles.formControl}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="tel">Phone Number</label>
                <input
                  type="tel"
                  name="tel"
                  value={formData.tel}
                  onChange={handleChange}
                  required
                  className={styles.formControl}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={5}
                  required
                  className={styles.formControl}
                />
              </div>
              <motion.div
                className={styles.modalFooter}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <button
                  type="submit"
                  className={styles.submitButton}
                  disabled={isButtonDisabled}
                >
                  Submit
                </button>
              </motion.div>
            </form>
          </div>
        </motion.div>
      )}

      {showMessageModal && (
        <motion.div
          className={styles.modal}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <h2>
                {modalVariant === "success" ? "Success" : "Failure"}
              </h2>
              <button
                className={styles.closeButton}
                onClick={handleCloseMessageModal}
              >
                &times;
              </button>
            </div>
            <div className={styles.modalBody}>
              <div className={`${styles.message} ${styles[modalVariant]}`}>
                {modalMessage}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default ContactForm;
