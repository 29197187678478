import styles from "./Home.module.css";
import BackgroundVideo from "react-background-video-player";
import LogoVideo from "../videos/ISPSVIDEO.mp4";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";

const sections = [
  {
    title: "Integrated Space Propulsion Solutions",
    text: "Precision propulsion, limitless horizons",
    skills: [],
    videoSrc: "",
  },
  {
    title: "",
    text: "",
    skills: [],
    videoSrc: LogoVideo,
  },
  {
    title: "Who We Are",
    text: "At ISP Solutions, our mission is to ensure greater mission success. Space and missile propulsion systems are complex and key to the success of any program. Our team of propulsion experts, spanning 100’s of years of propulsion expertise, can bring the depth, breadth and experience to bear on multiple propulsion solutions and help customers deliver successful systems to market, in a timely fashion.",
    skills: [],
    videoSrc: "",
  },
  {
    title: "What We Offer",
    text: "Our experienced team of propulsion experts will help your company to push the boundaries of propulsion solutions from Program management, design concepts, system design for cost and manufacturing, product selection, and system assembly and test to ensure on-time deliveries. In a market of rapid production and increased launch rates, missteps can be costly, both fiscally and on-time delivery. Our team will help your company to mitigate those mistakes. We are dedicated to delivering cutting-edge solutions and expert guidance in propulsion systems for spacecraft and missiles, fostering innovation, sustainability, and efficiency in the aerospace industry. Our team also mentors inexperienced teams as new programs staff up to meet the increased demands of today's marketplace.",
    skills: [],
    videoSrc: "",
  },
  {
    title: "Our Services",
    text: "ISP Solutions is a full-service propulsion engineering firm, offering expertise in the following areas:",
    skills: [
      "Propulsion System Sizing and Design",
      "Spacecraft and Launch Vehicle Performance Analysis",
      "Program Management",
      "Program Planning and Schedule Development",
      "System Engineering, Integration and Technical Support",
      "Propulsion Staff Mentoring",
      "Mission Planning",
      "Safety and Reliability Analysis",
      "Failure Investigation Support",
    ],
    videoSrc: "",
  },
];

const Home = ({ isHomePage }) => {
  return (
    <div className={styles.homeContainer}>
      {sections.map((section, index) => (
        <div key={index} className={`${styles.homeRow} ${styles[`homeImage${index + 1}`]}`}>
          {index === 1 ? (
            <div className={styles.homeVideoContainer}>
              <BackgroundVideo loop muted autoPlay src={section.videoSrc} />
            </div>
          ) : (
            <div className={styles.backgroundImage}></div>
          )}
          <div className={styles.textContainer}>
            <h1>{section.title}</h1>
            <div className={styles.textContent}>
              <p>{section.text}</p>
              {section.skills.length > 0 && (
                <ul className={styles.skillsList}>
                  {section.skills.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>
              )}
              {index !== 1 && (
                <Link to="/meet-us" className={styles.contactButton}>
                  Contact Us
                </Link>
              )}
            </div>
          </div>
          {isHomePage && index === sections.length - 1 && <Footer />}
        </div>
      ))}
    </div>
  );
};

export default Home;
