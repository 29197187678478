import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ContactForm from "../ContactForm/ContactForm";
import rogerImage from "../../images/rogerImage.webp";
import timImage from "../../images/timImage.webp";
import styles from "./Meet.module.css";

const profiles = [
  {
    name: "Roger McNamara",
    image: rogerImage,
    bio: "Dr. Roger McNamara’s significant contributions to the aerospace industry and community over his 44-year career in the aerospace industry include early research into and characterization of the hazards of orbital debris to space vehicles and operations, and potential mitigation strategies; improvements in space vehicle design and development, for both manned and unmanned systems; and most significantly, leadership in advancing technological innovation for the new era of human deep space exploration.\n\nDr. McNamara started his career as a mass properties and trajectory analyst for Martin Marietta in support of NASA’s Space Shuttle Program. Throughout his career he has supported several expendable launch vehicle, and spacecraft programs, as well as serving as Program Manager for commercial and DOD programs. Dr. McNamara’s extraordinary leadership of the historic NASA Orion Exploration Flight Test-1 successful mission was a critical step in NASA’s long-range plan to enable human exploration of deep space. He has received numerous government and industry awards; and has authored and co-authored numerous technical papers. As a 46-year member of the AIAA, Dr. McNamara has continued to show leadership at both the local and national support of various committees and subcommittees. Dr. McNamara received his Bachelor degree in Aeronautical Engineering from Embry Riddle Aeronautical University, and his Masters and Doctorate Degrees in Aerospace Engineering Sciences from the University of Colorado. He has maintained close ties with the University of Colorado, offering lectures, research support, and professional assistance.",
    emailjsCredentials: {
      serviceId: "service_jqj7dsr",
      templateId: "template_rtdd5zb",
      userId: "Z2Md0cXPMZGAXRRoT",
    },
  },
  {
    name: "Tim Held",
    image: timImage,
    bio: "Tim’s Propulsion experience includes design, analysis, build and test of space programs that include Space Shuttle, External Tank, Magellan, various MARS missions, Stardust, Cassini, Juno, GOES, the Orion capsule, Challenger return-to-flight investigation, Communications satellites and multiple defense strategic programs. His leadership in the space industry culminated in Director of Engineering at LM spanning a career of 30+ years, and a Chief Engineer at Ball Aerospace in the Advanced Missions and Technology group for 8 years. Tim’s leadership experience also includes 24 years as a Reservist in the Navy Intelligence field as Commanding Officer of National Intelligence units, culminating in his retirement as a Captain (O6). The experience also allowed him to be a user of many strategic asset data sets.",
    emailjsCredentials: {
      serviceId: "service_sff0ihr",
      templateId: "template_mimodz8",
      userId: "8fhE3ZOCl-Ef7Zbex",
    },
  },
];

const ProfileCard = ({ profile }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <motion.div
        className={styles.profileContainer}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <div className={styles.row}>
          <motion.div className={styles.imageCol}>
            <img
              src={profile.image}
              alt={profile.name}
              className={styles.headshots}
            />
          </motion.div>
          <div className={styles.bioContainer}>
            <h2 className={styles.name}>{profile.name}</h2>
            <p>{profile.bio}</p>
            <div className={styles.buttonContainer}>
              <motion.button
                onClick={handleShowModal}
                className={styles.aboutButton}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Contact {profile.name.split(" ")[0]}
              </motion.button>
              <Link to="/" className={styles.linkButton}>
                <motion.button
                  className={styles.homeButton}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Home
                </motion.button>
              </Link>
            </div>
            <ContactForm
              show={showModal}
              onHide={handleCloseModal}
              contactName={profile.name}
              {...profile.emailjsCredentials}
            />
          </div>
        </div>
      </motion.div>
    </>
  );
};

const Meet = () => {
  return (
    <div className={styles.meet}>
      {profiles.map((profile, index) => (
        <ProfileCard profile={profile} key={index} />
      ))}
    </div>
  );
};

export default Meet;
