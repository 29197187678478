import styles from "./Footer.module.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <span>© {currentYear}, ISP Solutions. All Rights Reserved.</span>
    </footer>
  );
}

export default Footer;
